import { dateTimeService } from '../dateTimeService';
import { HttpServiceBase, JsonString, PagedResponse, RequestMethod } from '../httpServiceBase';
import { ReducedUser } from '../usersService';

export enum InterviewCollectionUploadType {
    Batch = 'batch',
    Single = 'single'
}

export enum InterviewCollectionGoal {
    ProblemValidation = 'ProblemValidation',
    BuyerPersona = 'BuyerPersona',
    WinLossAnalysis = 'WinLossAnalysis',
    Other = 'Other'
}

export type MinimalInterviewCollection = {
    id: number;
    createdBy: ReducedUser | null;
    name: string;
    goal: InterviewCollectionGoal;
    otherGoal?: string | null;
    lastActivity: Date;
};

export type InterviewCollection = MinimalInterviewCollection & {
    objectives: string[];
    analysis: InterviewCollectionAnalysis;
};

export type InterviewInsightTopic = {
    topic: string;
    description: string;
    objective?: string;
    questions: string[];
};

export type InterviewCollectionAnalysis = {
    pending: boolean;
    topics: InterviewInsightTopic[] | null;
    insights?: InterviewCollectionInsight[] | null;
    summary?: InterviewCollectionInsightSummary | null;
    analyzedInterviewsCount: number;
};

export type InterviewCollectionInsight = {
    topic: string;
    description: string;
    objective?: string | null;
    questions: string[];
    summaryInsights: string[];
};

export type InterviewCollectionInsightSummary = {
    points: string[];
};

export type NewInterviewCollectionData = Pick<MinimalInterviewCollection, 'name' | 'goal' | 'otherGoal'> & { objective?: string | null };

export type UpdateInterviewCollectionData = Partial<Pick<MinimalInterviewCollection, 'name'>>;

export type MinimalCollectionInterview = {
    id: number;
    title: string;
    date?: Date | null;
    host?: string | null;
    interviewee?: string | null;
    qualityScore?: number | null;
    scriptCoverage?: number | null;
    insightCoverage?: number | null;
};

export type CollectionInterview = MinimalCollectionInterview & {
    script?: CollectionInterviewFile | null;
    file: CollectionInterviewFile;
    fileIsTranscript?: boolean | null;
    analysis?: CollectionInterviewAnalysis;
};

export type CollectionInterviewFile = {
    id: number;
    name: string;
};

export type CollectionInterviewAnalysis = {
    id: number;
    pending: boolean;
    summary?: string | null;
    qualityAnalysis?: CollectionInterviewQualityAnalysis | null;
    scriptAnalysis?: CollectionInterviewScriptAnalysis | null;
    insightAnalysis?: CollectionInterviewInsightAnalysis | null;
};

export type QualitySuggestion = {
    principle: string;
    description: string;
};

export type CollectionInterviewQualityAnalysis = {
    summary: string;
    suggestions: QualitySuggestion[];
};

export type CollectionInterviewScriptAnalysis = {
    strengths: string[];
    areasForImprovement: string[];
};

export type CollectionInterviewInsightAnalysis = {
    insights: CollectionInterviewInsight[];
};

export type CollectionInterviewInsight = {
    topic: string;
    insight: string;
};

export type NewCollectionInterviewData = Pick<MinimalCollectionInterview, 'host' | 'interviewee' | 'date'>;
export type UpdateCollectionInterviewData = Partial<NewCollectionInterviewData>;

export type AIConversationMessage = { role: 'assistant' | 'user'; content: string };
class InterviewCollectionsService extends HttpServiceBase {
    constructor() {
        super('/api/experiments/interview2');
    }

    private static ensureInterviewCollectionDateFields<TCollections extends MinimalInterviewCollection>(entry: TCollections): TCollections {
        dateTimeService.ensureDateField(entry, 'lastActivity');

        return entry;
    }

    private static ensureInterviewDateFields<TInterview extends MinimalCollectionInterview>(entry: TInterview): TInterview {
        dateTimeService.ensureDateField(entry, 'date');

        return entry;
    }

    getInterviewCollections(ideaId: string): Promise<MinimalInterviewCollection[]> {
        return this.performRequest<PagedResponse<'collections', MinimalInterviewCollection>>({
            path: `/${ideaId}/interview-collections`
        }).then(r => {
            r.collections.forEach(InterviewCollectionsService.ensureInterviewCollectionDateFields);

            return r.collections;
        });
    }

    getInterviewCollection(ideaId: string, collectionId: number): Promise<InterviewCollection> {
        return this.performRequest<InterviewCollection>({
            path: `/${ideaId}/interview-collections/${collectionId}`
        }).then(InterviewCollectionsService.ensureInterviewCollectionDateFields);
    }

    createInterviewCollection(ideaId: string, data: NewInterviewCollectionData): Promise<InterviewCollection> {
        return this.performRequest<InterviewCollection>({
            path: `/${ideaId}/interview-collections`,
            method: RequestMethod.POST,
            body: data
        }).then(InterviewCollectionsService.ensureInterviewCollectionDateFields);
    }

    createInterviewCollectionWithInterviews(ideaId: string, data: NewInterviewCollectionData, interviewsFiles: File[], script?: File): Promise<InterviewCollection> {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => formData.append(key, value ?? ''));
        interviewsFiles.forEach(file => formData.append('files', file));
        script && formData.append('script', script);
        return this.performRequest<InterviewCollection>({
            path: `/${ideaId}/interview-collections/with-interviews`,
            method: RequestMethod.POST,
            body: formData
        }).then(InterviewCollectionsService.ensureInterviewCollectionDateFields);
    }

    updateInterviewCollection(ideaId: string, collectionId: number, data: UpdateInterviewCollectionData): Promise<InterviewCollection> {
        return this.performRequest<InterviewCollection>({
            path: `/${ideaId}/interview-collections/${collectionId}`,
            method: RequestMethod.PATCH,
            body: data
        }).then(InterviewCollectionsService.ensureInterviewCollectionDateFields);
    }

    deleteInterviewCollection(ideaId: string, collectionId: number): Promise<unknown> {
        return this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/interview-collections/${collectionId}`,
            method: RequestMethod.DELETE
        });
    }

    createInterview(ideaId: string, collectionId: number, data: NewCollectionInterviewData, file: File, script?: File): Promise<CollectionInterview> {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => value && formData.append(key, value instanceof Date ? value.toISOString() : value));
        formData.append('file', file);
        script && formData.append('script', script);

        return this.performRequest<CollectionInterview>({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews`,
            method: RequestMethod.POST,
            body: formData
        }).then(InterviewCollectionsService.ensureInterviewDateFields);
    }

    updateInterview(
        ideaId: string,
        collectionId: number,
        interviewId: number,
        data: UpdateCollectionInterviewData,
        script?: File
    ): Promise<CollectionInterview> {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => value && formData.append(key, value instanceof Date ? value.toISOString() : value));
        script && formData.append('script', script);

        return this.performRequest<CollectionInterview>({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews/${interviewId}`,
            method: RequestMethod.PATCH,
            body: formData
        }).then(InterviewCollectionsService.ensureInterviewDateFields);
    }

    deleteInterview(ideaId: string, collectionId: number, interviewId: number): Promise<unknown> {
        return this.performRequest({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews/${interviewId}`,
            method: RequestMethod.DELETE
        });
    }

    getInterviews(ideaId: string, collectionId: number): Promise<MinimalCollectionInterview[]> {
        return this.performRequest<PagedResponse<'interviews', MinimalCollectionInterview>>({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews`
        }).then(r => {
            r.interviews.forEach(InterviewCollectionsService.ensureInterviewDateFields);

            return r.interviews;
        });
    }

    getInterview(ideaId: string, collectionId: number, interviewId: number): Promise<CollectionInterview> {
        return this.performRequest<CollectionInterview>({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews/${interviewId}`
        }).then(InterviewCollectionsService.ensureInterviewDateFields);
    }

    async *askQuestion(ideaId: string, collectionId: number, interviewId: number | undefined, conversation: AIConversationMessage[]): AsyncIterable<string> {
        const response = await this.performRequestWithoutParsingResponse({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews${interviewId !== undefined ? `/${interviewId}` : ''}/ask-iva`,
            body: { messages: conversation },
            method: RequestMethod.POST,
            headers: {
                Accept: 'text/event-stream'
            }
        });

        const eventsIterator = this.processSseResponse(response);
        for await (const event of eventsIterator) yield event.data;
    }

    addInterviewCollectionObjective(ideaId: string, collectionId: number, objective: string): Promise<InterviewCollection> {
        return this.performRequest<InterviewCollection>({
            path: `/${ideaId}/interview-collections/${collectionId}/objectives`,
            method: RequestMethod.POST,
            body: { text: objective }
        }).then(InterviewCollectionsService.ensureInterviewCollectionDateFields);
    }

    removeInterviewCollectionObjective(ideaId: string, collectionId: number, atIndex: number): Promise<InterviewCollection> {
        return this.performRequest<InterviewCollection>({
            path: `/${ideaId}/interview-collections/${collectionId}/objectives`,
            method: RequestMethod.DELETE,
            queryParams: { atIndex: String(atIndex) }
        }).then(InterviewCollectionsService.ensureInterviewCollectionDateFields);
    }

    getInterviewScriptContent(ideaId: string, collectionId: number, interviewId: number): Promise<string> {
        return this.performRequest<JsonString>({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews/${interviewId}/script/text`
        }).then(r => r.text);
    }

    getInterviewFileContent(ideaId: string, collectionId: number, interviewId: number): Promise<string> {
        return this.performRequest<JsonString>({
            path: `/${ideaId}/interview-collections/${collectionId}/interviews/${interviewId}/file/text`
        }).then(r => r.text);
    }
}

export const interviewCollectionsService = new InterviewCollectionsService();
