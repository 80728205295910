import { ZIndexContext } from '@progress/kendo-react-common';
import { Dialog } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ComponentType, ReactNode, useRef, useState } from 'react';
import { To } from 'react-router';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import { ReactComponent as AssumptionsIcon } from '../../icons/assumption.svg';
import { ReactComponent as GlossaryIcon } from '../../icons/book-open.svg';
import { ReactComponent as CanvasIcon } from '../../icons/canvas.svg';
import { ReactComponent as ContactsIcon } from '../../icons/contacts.svg';
import { ReactComponent as InterviewsIcon } from '../../icons/interview.svg';
import { ReactComponent as JourneyIcon } from '../../icons/map.svg';
import { ReactComponent as CalendarIcon } from '../../icons/schedule.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as TestsIcon } from '../../icons/test.svg';
import { JourneyTaskNavigationHighlight } from '../../services/journeyService';
import { UserRole, usersService } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setCurrentUser } from '../../state/user/userSlice';
import { InterviewCollectionsAnnouncement } from '../experiments/interviewCollections/interviewCollectionsAnnouncement';
import { InterviewCollectionsSidebarNavLink } from '../experiments/interviewCollections/interviewCollectionsSidebarNavLink';
import { InfoTip } from '../idea/infoTip';
import { H1 } from '../ui/typography';

const navLinkClassName = 'k-button k-button-lg k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icp-svg-icon-button k-icp-tooltip-trigger';
export default function Sidebar() {
    const currentRole = useAppSelector(s => s.idea.role);
    const journeyLinkElementRef = useRef<HTMLAnchorElement>(null);

    return (
        <ZIndexContext.Provider value={150}>
            <StackLayout
                orientation="vertical"
                align={{ horizontal: 'center', vertical: 'top' }}
                className="side-nav k-justify-content-between k-gap-4 k-pt-6 k-pb-4 k-px-3"
            >
                <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-4">
                    <NavLinksWrapper>
                        <NavLink ref={journeyLinkElementRef} to="./journey" title="Journey" className={navLinkClassName}>
                            <JourneyIcon className="k-icp-icon" />
                        </NavLink>
                        <InfoTip name={JourneyTaskNavigationHighlight.TaskUnlockedNotice} anchor={journeyLinkElementRef.current}>
                            You have unlocked a new task
                        </InfoTip>
                        <NavLink to="./canvas" title="Canvas" className={navLinkClassName}>
                            <CanvasIcon className="k-icp-icon" />
                        </NavLink>
                        <NavLink to="./hypotheses" title="Hypotheses" className={navLinkClassName}>
                            <AssumptionsIcon className="k-icp-icon" />
                        </NavLink>
                        <NavLink to="./research" title="Research" className={navLinkClassName}>
                            <TestsIcon className="k-icp-icon" />
                        </NavLink>
                        <NavLink to="./calendar" title="Calendar" className={navLinkClassName}>
                            <CalendarIcon className="k-icp-icon" />
                        </NavLink>
                        <NavLink to="./contacts" title="Contacts" className={navLinkClassName}>
                            <ContactsIcon className="k-icp-icon" />
                        </NavLink>
                    </NavLinksWrapper>

                    <ExperimentsNavItemsList />

                    <div className="full-horizontal-separator"></div>

                    <NavLinksWrapper>
                        <NavLink to="./glossary" title="Glossary" className={navLinkClassName}>
                            <GlossaryIcon className="k-icp-icon" />
                        </NavLink>
                        {currentRole && currentRole !== UserRole.Viewer && (
                            <NavLink to="./settings" title="Settings" className={navLinkClassName}>
                                <SettingsIcon className="k-icp-icon" />
                            </NavLink>
                        )}
                    </NavLinksWrapper>
                </StackLayout>
            </StackLayout>
        </ZIndexContext.Provider>
    );
}

function NavLinksWrapper({ children }: { children?: ReactNode }) {
    return (
        <Tooltip anchorElement="target" position="right" openDelay={0}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-6">
                {children}
            </StackLayout>
        </Tooltip>
    );
}

type ExperimentDefinition = {
    key: string;
    icon: ComponentType<React.SVGProps<SVGSVGElement>>;
    tooltip: string;
    link: To;
    navLinkComponent?: ComponentType<NavLinkProps>;
    announcement?: {
        title: string;
        ctaText: string;
        content: ComponentType;
    };
};
const activeExperiments: ExperimentDefinition[] = [
    {
        key: 'ExperimentalInterviewProcessing',
        icon: InterviewsIcon,
        tooltip: 'Interviews Collections',
        link: './interviews-collections',
        navLinkComponent: InterviewCollectionsSidebarNavLink,
        announcement: {
            title: 'Transform Interviews into Actionable Insights',
            ctaText: 'Start now',
            content: InterviewCollectionsAnnouncement
        }
    }
];
function ExperimentsNavItemsList() {
    const currentUserFlags = useAppSelector(s => s.user?.flags);
    const dispatch = useAppDispatch();
    const [suppressAnnouncements, setSuppressAnnouncements] = useState<boolean>();
    if (!currentUserFlags) return null;

    function getExperimentUserFlag(experimentKey: string, dismissed: boolean) {
        return dismissed ? `${experimentKey}_Dismissed` : experimentKey;
    }

    const availableExperiments = activeExperiments.filter(experiment => currentUserFlags.includes(getExperimentUserFlag(experiment.key, false)));
    if (!availableExperiments.length) return null;

    const experimentsToBeAnnounced = availableExperiments.filter(
        experiment => experiment.announcement && !currentUserFlags.includes(getExperimentUserFlag(experiment.key, true))
    );

    async function ensureUserFlag(flag: string) {
        if (!currentUserFlags) throw new Error('Flags not loaded');
        if (currentUserFlags.includes(flag)) return;

        const updatedUser = await usersService.partiallyUpdateCurrent({ flags: [...currentUserFlags, flag] });
        dispatch(setCurrentUser(updatedUser));
    }

    async function closeAnnouncement(experimentKey: string) {
        setSuppressAnnouncements(true);
        try {
            await ensureUserFlag(getExperimentUserFlag(experimentKey, true));
        } finally {
            setSuppressAnnouncements(false);
        }
    }

    return (
        <>
            <div className="full-horizontal-separator" />
            <NavLinksWrapper>
                {availableExperiments.map(experiment => {
                    const NavLinkComponent: ComponentType<NavLinkProps> = experiment.navLinkComponent ?? NavLink;

                    return (
                        <NavLinkComponent key={experiment.key} to={experiment.link} title={experiment.tooltip} className={navLinkClassName}>
                            <experiment.icon className="k-icp-icon" />
                        </NavLinkComponent>
                    );
                })}
                {!suppressAnnouncements &&
                    experimentsToBeAnnounced.map(experiment => {
                        const announcementData = experiment.announcement!;
                        return (
                            <ExperimentAnnouncement
                                key={experiment.key}
                                title={announcementData.title}
                                ctaLink={experiment.link}
                                ctaText={announcementData.ctaText}
                                onClose={() => closeAnnouncement(experiment.key)}
                            >
                                <announcementData.content />
                            </ExperimentAnnouncement>
                        );
                    })}
            </NavLinksWrapper>
        </>
    );
}

function ExperimentAnnouncement({
    title,
    ctaText,
    ctaLink,
    children,
    onClose
}: {
    title: string;
    ctaText: string;
    ctaLink: To;
    children?: ReactNode;
    onClose?: () => void;
}) {
    return (
        <Dialog title=" " className="k-icp-dialog-no-title k-icp-dialog-responsive" onClose={onClose} width={992}>
            <StackLayout orientation="vertical" align={{ horizontal: 'center', vertical: 'top' }} className="k-gap-10 k-mb-8 k-mx-12">
                <H1 className="k-text-center">{title}</H1>
                <div>{children}</div>
                <Link
                    to={ctaLink}
                    onClick={onClose}
                    className="k-button k-button-lg k-button-solid k-button-solid-primary k-rounded-md"
                    style={{ minWidth: 240 }}
                >
                    {ctaText}
                </Link>
            </StackLayout>
        </Dialog>
    );
}
